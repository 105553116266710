@import 'themes-vars.module.scss';

html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    padding: 0;
    font-family: Arial, Helvetica, sans-serif;
}

.loader-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}
.action-bar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2rem;
}
.content-header {
    display: flex;
    align-items: center;
    gap: 10px;
}
.campaigns-table {
    padding-bottom: 2rem;
}
.input-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    input {
        display: block;
        margin-bottom: 15px;
        padding: 0.5rem;
    }
    select {
        display: block;
        margin-bottom: 15px;
        padding: 0.5rem;
    }
    label {
        color: white;
        opacity: 0.9;
        padding-left: 0.1rem;
        font-size: 0.9rem;
    }
    .error-message {
        font-size: 0.8rem;
        color: yellow;
        margin-top: -15px;
    }
}
form {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.gap-1 {
    gap: 1rem;
}
.error-message {
    color: red;
    margin-bottom: 0.5rem;
}
.input-label {
    color: white !important;
    margin-bottom: 10px !important;
}
.input-label-card {
    color: #605f99 !important;
    font-weight: bold !important;
    margin-bottom: 10px !important;
}
.node-section-port-indication {
    margin-top: 5px;
    height: 0.9rem;
    width: 0.9rem;
    background-color: #bbb;
    border-radius: 50%;
    border: 1px solid black;
    display: inline-block;
}
// campaign
.campaign-detail-container {
    box-sizing: border-box;

    &.smsbot {
        display: block;
        flex-flow: row;
        box-sizing: border-box;
    }

    &.voicebot {
        display: flex;
        flex-flow: row;
    }

    a:hover {
        background: $secondaryBgPinkHover;
    }
    .field {
        background-color: $secondaryBgPink;
        display: inline-block;

        &.voicebot {
            width: 50%;
        }
        &.smsbot {
            width: 70%;
        }
    }
    .field-container {
        margin: 1.1rem;
        justify-content: space-between !important;
        display: flex;
        flex-wrap: wrap;
    }
    .outbound-calls-table {
        margin-top: 2rem;
    }
    .chart {
        box-sizing: border-box;
        padding: 2rem 0 0;
        &.smsbot {
            display: flex;
            width: 70%;
            padding: 0 0 0;
        }
        &.voicebot {
            display: inline-block;
            vertical-align: top;
            width: 50%;
        }
        .dashboard {
            margin: 2rem auto 0;
            width: 80%;
            .chart-box {
                margin-top: 15px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                -ms-flex-direction: row;
                flex-direction: row;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                .pie-chart {
                    margin: auto;
                    position: relative;
                    padding-top: 20px;
                    padding-bottom: 20px;

                    .chart-middle-box {
                        position: absolute;
                        line-height: 1.4;
                        font-size: 1.4rem;
                        left: 50%;
                        top: 50%;
                        -webkit-transform: translate(-50%, -50%);
                        transform: translate(-50%, -50%);
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-orient: vertical;
                        -webkit-box-direction: normal;
                        -ms-flex-direction: column;
                        flex-direction: column;
                        text-align: center;
                        color: black;
                    }
                    .chart-middle-box > *:last-child {
                        color: $secondary200;
                    }
                }
                .legend {
                    margin-left: 30px;
                    .label {
                        margin-bottom: 5px;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-pack: justify;
                        -ms-flex-pack: justify;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                    }
                    .label-box {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-pack: center;
                        -ms-flex-pack: center;
                        justify-content: center;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        white-space: nowrap;
                        width: 35px;
                        height: 35px;
                        border-radius: 50%;
                        color: #000;
                    }
                    .label-text {
                        margin-left: 10px;
                        width: 110px;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-pack: justify;
                        -ms-flex-pack: justify;
                        justify-content: space-between;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                    }
                }
            }
        }
    }
    .information {
        text-align: left;
        color: black;
    }
    .wide-input {
        width: 100%;
    }
    h3,
    h4 {
        margin-top: 0;
        margin-bottom: 0.5rem;
    }
    .Mui-disabled {
        color: white;
        -webkit-text-fill-color: black;
    }
    .bot-card {
        background: $secondaryBgPinkDark;
        box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%),
            0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
    }
    .detail-form-card {
        padding: 24px;
        background: $secondaryBgPinkDark;
        box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%),
            0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
    }
}
// bot
.bot-detail-container {
    display: flex;
    box-sizing: border-box;
}
.bot-header-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
// drag and drop
.dropzone {
    text-align: center;
    padding: 30px;
    border: 3px dashed #eeeeee;
    background-color: #fafafa;
    color: #bdbdbd;
    cursor: pointer;
    margin-top: 0.5rem;
    margin-bottom: 20px;
}

.selected-file-wrapper {
    text-align: center;
    margin-bottom: 15px;
}

.selected-file {
    color: #000;
    font-weight: bold;
}
.alert {
    color: red;
    font-weight: bold;
}
// analytics
.customized-tooltip-content {
    border: 1px solid black;
    padding: 5px 15px 0 15px;
    background-color: white;
}

.conversation-view {
    font-family: Open Sans, sans-serif;
    font-size: 1rem;

    .item {
        cursor: pointer;
        margin: 8px;
        padding: 12px;
        border: 1px solid #d1d5d9;
        border-radius: 16px;
    }

    .item-not-text {
        border: 1px solid transparent;
        margin-right: 40px;
        padding: 0px;
    }

    .item-bot {
        background: #f5f5f5;
        margin-right: 40px;
    }

    .item-human {
        background: #f5dae9;
        margin-left: 40px;
    }

    .item-active {
        border: 2px solid #888888;
    }

    .item-system {
        color: gray;
    }
}

.markdown-content {
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.6;
    color: #333;
    padding: 20px;

    a {
        color: $secondary200;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    code {
        font-family: Arial, sans-serif;
        background-color: #f5f5f5;
        border-radius: 4px;
        padding: 2px 4px;
    }

    pre {
        background-color: #f5f5f5;
        border-radius: 4px;
        overflow-x: auto;
        padding: 10px;
    }

    img {
        max-width: 900px;
        max-height: 500px;
        display: block;
        margin: 0 auto;
    }

    ul,
    ol {
        margin-left: 20px;
        padding-left: 20px;

        li {
            margin-bottom: 8px;
        }
    }

    h1,
    h2,
    h3 {
        margin-top: 1.5em;
        color: #222;
    }
}
