.base-node {
    min-height: 50px;
    width: 250px;
    border: 1px solid white;
    padding: 5px;
    border-radius: 5px;
    text-align: center;
    color: white;
}

.start-node,
.end-node {
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #4e4e4e;
    padding: 5px;
    border-radius: 5px;
    color: white;
    text-align: center;
    box-shadow: 4px 6px 12px -5px rgba(0, 0, 0, 0.75);
}

.start-node {
    background: linear-gradient(
        0deg,
        rgba(0, 128, 0, 1) 0%,
        rgba(0, 191, 0, 1) 100%
    );
}

.end-node {
    background: linear-gradient(
        0deg,
        rgba(128, 128, 128, 1) 0%,
        rgba(189, 189, 189, 1) 100%
    );
}

.base-node label {
    display: block;
    color: rgb(216, 216, 216);
    font-size: 12px;
}
