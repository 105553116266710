:root {
  // colors
  --mchat-primary: #dd8a87;
  --mchat-secondary: #252e42; //background
  --mchat-terciary: #595f6e;
  --mchat-user-input-section: #8c919b;
  --mchat-text: #fafafa;
  // font
  --mchat-font: Open Sans, sans-serif;
  --mchat-radius: 25px;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

.mchat-container {
  height: 100%;
  font-size: 16px;
  font-family: var(--mchat-font);
  line-height: 20px;
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;

  @media screen and (min-width: 700px) {
    max-height: 652px;
  }

  &--closed {
    max-height: 132px;
  }

  // Scrollbar
  /* width */
  ::-webkit-scrollbar {
    width: 0.5rem;
  }
  /* Track */
  // ::-webkit-scrollbar-track {
  //   box-shadow: inset 0 0 5px grey;
  // }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--mchat-user-input-section);
    border-radius: 20px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--mchat-terciary);
  }

  a {
    color: var(--mchat-primary);
  }
}

.mchat {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background: rgb(37, 46, 66);
  background: linear-gradient(
    180deg,
    var(--mchat-secondary) 0%,
    var(--mchat-terciary) 100%
  );
  color: var(--mchat-text);
  display: flex;
  flex-direction: column;
  width: 100vw;

  @media (min-width: 700px) {
    margin-right: 32px;
    margin-bottom: 32px;
    margin-top: 32px;
    border-radius: var(--mchat-radius);
    width: 408px;
  }
}


.mchat-text-with-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
}

.mchat-primary-color {
  color: var(--mchat-primary);
}

.mchat-time-left-icon {
  background-color: var(--mchat-primary);
  -webkit-mask: url(../assets/time-left.svg) no-repeat center;
  mask: url(../assets/time-left.svg) no-repeat center;
  width: 1.5rem;
  height: 1.5rem;
}

.mchat-header {
  min-height: 52px;
  display: flex;
  justify-content: space-between;
  background-color: var(--mchat-primary);
  overflow: hidden;
  @media (min-width: 700px) {
    border-radius: var(--mchat-radius) var(--mchat-radius) 0 0;
  }
}

.mchat-header__title {
  padding: 1rem;
}

.mchat-hyperlinks-group {
  display: flex;
  flex-wrap: wrap;

  & > * {
    margin-left: 5px;
    min-width: 0;
    overflow-wrap: anywhere;
  }
}

.mchat-hyperlink {
  display: flex;
  margin-top: 4px;
  font-style: italic;
  font-weight: bolder;
  text-decoration: none;
  width: fit-content;

  &:hover {
    color: #efedea;
    opacity: 0.9;
    background-color: var(--mchat-secondary);
    border-color: var(--mchat-secondary);

    .mchat-hyperlink__icon {
      background-color: var(--mchat-secondary);
    }
  }
}

.mchat-hyperlink__icon {
  background-color: var(--mchat-dark);
  -webkit-mask: url(../assets/open-external-link-icon.svg) no-repeat center;
  mask: url(../assets/open-external-link-icon.svg) no-repeat center;
  width: 20px;
  margin-left: 10px;
}

.mchat-body {
  position: relative;
  padding: 1rem;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  flex-grow: 1;

  & > * {
    max-width: 100%;
    overflow-wrap: anywhere;
  }

  a {
    overflow-wrap: anywhere;
  }
}

.mchat-bubble {
  margin-top: 0.5rem;
  padding: 1rem;

  &--in {
    background-color: var(--mchat-terciary);
    border-radius: 0 var(--mchat-radius) var(--mchat-radius);
  }

  &--out {
    background-color: var(--mchat-primary);
    border-radius: var(--mchat-radius) 0 var(--mchat-radius) var(--mchat-radius);
  }
}

.mchat-bubble__link {
  margin-top: 0.25rem;
  display: block;
  color: var(--mchat-primary);
}

.mchat-image {
  margin-top: 8px;
  max-width: 408px;
}

.mchat-bubble__title {
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
  font-weight: 600;
}

.mchat-input-section {
  padding: 1.5rem 1rem 1.25rem;
  display: flex;
  gap: 0.5rem;
}

.mchat-audio-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1rem;
  border-radius: var(--mchat-radius);
  border: none;
  background-color: var(--mchat-user-input-section);
  color: var(--mchat-white);
  font-size: 16px;
}

@keyframes pulse-red {
  0% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(255, 167, 74, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

.mchat-input-section__input {
  width: 100%;
  padding: 1rem;
  border-radius: var(--mchat-radius);
  border: none;
  background-color: var(--mchat-user-input-section);
  color: var(--mchat-white);
  font-size: 16px;

  &:focus-visible,
  &:visited {
    outline: none;
  }
}

.mchat-input-section__input::placeholder {
  color: var(--mchat-text);
}

.mchat-action-button {
  border: 1px solid var(--mchat-primary);
  border-radius: 10px;
  background-color: var(--mchat-primary);
  color: black;
  font-size: 16px;
  padding: 8px 16px;
  cursor: pointer;
  display: flex;
  margin-top: 8px;

  &:hover {
    color: var(--mchat-text);
  }
}

.mchat-action-button--secondary {
  border: 1px solid var(--mchat-primary);
  background-color: transparent;
  color: var(--mchat-primary);
}

.mchat-input-section__button {
  border-radius: 30px;
  border: none;
  padding: 16px;
  min-width: 50px;
  max-width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: var(--mchat-user-input-section);

  &:hover {
    filter: brightness(85%);
  }

  &:active {
    background-color: var(--mchat-primary);
  }

  &--audio-active {
    background-color: var(--mchat-primary);
  }

  &--audio-pulsing {
    border-radius: 50%;
    animation: pulse-red 2s infinite;
  }

  &:disabled {
    opacity: 0.3;
    cursor: default;
    &:hover {
      filter: none;
    }

    &:active {
      background-color: var(--mchat-user-input-section);
    }
  }

  &.mchat-input-section__button--input-character-counter:disabled {
    opacity: 1;
  }
}

.mchat-input-section__character-counter {
  color: red;
  font-weight: bold;
}

.mchat-message {
  display: flex;
  flex-direction: column;
  width: 100%;

  &--out {
    flex-direction: row-reverse;
  }
}

.mchat-typing-message {
  width: auto;
}

.mchat-typing-message__svg {
  width: 30px;
  fill: var(--mchat-text);
}

@keyframes typing {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(3px);
  }
  100% {
    transform: translateY(0);
  }
}

.mchat-typing-message__circle-1 {
  animation: typing 1s infinite;
}

.mchat-typing-message__circle-2 {
  animation: typing 1s infinite 0.2s;
}

.mchat-typing-message__circle-3 {
  animation: typing 1s infinite 0.4s;
}

.mchat-debug-message {
  font-size: 90%;
  color: white;

  .mchat-bubble {
    background-color: #333131;
    display: flex;
    flex-direction: column;
  }
}

.mchat-debug-message__title {
  vertical-align: top;
  padding: 0;
  margin: 0;
}

.mchat-debug-message__items {
  display: flex;
  flex-direction: column;
  word-break: break-all;
  list-style: none;
  padding-inline-start: 10px;
}

.mchat-closer {
  padding: 1rem;
  cursor: pointer;
  border: none;
  background: var(--mchat-primary);
  color: var(--mchat-text);
}

.mchat-closer-icon {
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
}

.mchat-opener {
  margin-bottom: 2rem;
  margin-right: 2rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 80px;
  height: 80px;
  background-color: var(--mchat-primary);
  border-radius: 100%;
  align-self: flex-end;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.mchat-opener__icon svg {
  color: white;
  width: 2rem;
  height: auto;
}

.mchat-hyperlink-button {
  border: 1px solid var(--mchat-primary);
  border-radius: 0 var(--mchat-radius) var(--mchat-radius) var(--mchat-radius);
  color: var(--mchat-primary);
  background-color: transparent;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  margin-top: 8px;

  a {
    display: flex;
    padding: 12px 30px 12px 16px;
    height: 100%;
    width: 100%;
    text-overflow: ellipsis;
    color: var(--mchat-primary);
    text-decoration: none;
  }

  &:hover {
    background-color: var(--mchat-primary);

    a {
      color: var(--mchat-text);
    }

    .mchat-hyperlink-button__icon {
      background-color: var(--mchat-text);
    }
  }
}


.mchat-hyperlink-button__icon {
  background-color: var(--mchat-primary);
  -webkit-mask: url(../assets/open-external-link-icon.svg) no-repeat center;
  mask: url(../assets/open-external-link-icon.svg) no-repeat center;
  width: 20px;
  margin-left: 10px;
}

.mchat-buttons-message {
  margin-top: 8px;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  align-items: flex-start;
}

.mchat-option-button {
  padding: 0.75rem 1rem;
  border: 1px solid var(--mchat-text);
  border-radius: var(--mchat-radius);
  color: var(--mchat-text);
  background-color: transparent;
  font-size: 1rem;
  cursor: pointer;
  display: flex;

  &:hover {
    background-color: var(--mchat-primary);
  }
}

.mchat-option-button--selected {
  background-color: var(--mchat-secondary);
  border-color: var(--mchat-secondary);
  pointer-events: none;
  color: var(--mchat-text);
}
.mchat-option-button:disabled {
  background-color: var(--mchat-user-input-section);
  border-color: var(--mchat-user-input-section);
  color: white;
  cursor: not-allowed;
}

.mchat-audio {
  position: absolute;
  left: 23rem;
  bottom: 3.25rem;
  height: 50px;

  --steady {
    background-color: #f4d03f;
  }

  --go {
    background-color: #28b463;
  }
}

.mchat-user-prompt-panel {
  flex-grow: 1;
  background-color: var(--mchat-secondary);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-bottom: 2rem;
}

.mchat-user-prompt-panel__prompt {
  display: flex;
  gap: 0.25rem;
  flex-direction: column;
}

.mchat-user-prompt-panel__actions {
  margin-top: 1rem;
}

.mchat-user-prompt-panel__actions {
  margin-top: 1rem;
  display: flex;
  gap: 10px;
}
